import { cn } from "@/lib/utils";
import React, { CSSProperties } from "react";

const H1 = ({
  children,
  className,
  ...props
}: {
  children: any;
  style?: CSSProperties;
  className?: string;
}) => {
  return (
    <h1
      {...props}
      className={cn(
        "font-semibold text-3xl md:text-4xl lg:text-5xl  text-white",
        className
      )}
    >
      {children}
    </h1>
  );
};

export default H1;
