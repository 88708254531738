import { cn } from "@/lib/utils";
import React from "react";

const GradientSpan = ({
  children,
  className,
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
>) => {
  return (
    <span
      className={cn(
        "bg-gradient-to-t from-[#FFFFFF69] to-[#FFFF] bg-clip-text text-transparent font-semibold",
        className
      )}
    >
      {children}
    </span>
  );
};

export default GradientSpan;
