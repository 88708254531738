"use client";
import { Card } from "@/components/ui/card";
import React, { useState, useEffect } from "react";
import Image from "next/image";
import Section from "@/components/new-components/wrapper/Section";

const YourEverydayHealthScore = () => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % card1ImgArr.length);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Section className="pt-10">
      <h2 className="font-semibold text-3xl md:text-5xl w-60 md:w-[45%] lg:w-[35%] mb-8 md:leading-[60px]">
        Your Everyday <span className="text-[#22C55E]">Health Score</span>
      </h2>
      <p className="text-lg md:text-2xl my-6 text-[#797979]">
        Track essential metrics such as{" "}
        <span className="text-white">
          sleep patterns, movement index, calories burned, and mindfulness,
        </span>{" "}
        seamlessly integrating health insights into your daily routine.
      </p>
      <p className="text-lg md:text-2xl mb-12 text-[#797979]">
        With real-time blood glucose monitoring and a readiness assessment, the
        Bonatra app empowers you to prioritize and enhance your overall health
        and fitness.
      </p>
      <div className="flex flex-col lg:flex-row gap-6 md:gap-6">
        <Card
          className="px-[16px] md:px-[190px] py-[24px] md:py-[86px] rounded-3xl"
          style={{  
            background: `linear-gradient(${card1ColorArr[currentIndex]})`
          }}
        >
          <Image
            src={card1ImgArr[currentIndex]}
            height={828}
            width={408}
            alt="img"
          />
        </Card>
        <Card
          className="relative px-[16px] md:px-[190px] py-[24px] md:py-[86px] rounded-3xl"
          style={{ 
            background: `linear-gradient(${card2ColorArr[currentIndex]})`,
          }}
        >
          {currentIndex === 1 && (
            <p className="absolute top-10 left-0 right-0 text-white font-semibold text-center">(Under Development)</p>
          )}
          <Image
            src={card2ImgArr[currentIndex]}
            height={828}
            width={408}
            alt="img"
          />
        </Card>
      </div>
    </Section>
  );
};

export default YourEverydayHealthScore;

const card1ImgArr = [
  "https://assets.bonatra.com/Website%20Assets/Movementindex1.webp",
  "https://assets.bonatra.com/Website%20Assets/Readinessscore1.webp",
  "https://assets.bonatra.com/Website%20Assets/Bloodglucose1.webp",
];
const card2ImgArr = [
  "https://assets.bonatra.com/Website%20Assets/Sleepscore1.webp",
  "https://assets.bonatra.com/Website%20Assets/Caloriesburned1.webp",
  "https://assets.bonatra.com/Website%20Assets/Mindfulness.webp",
];
const card1ColorArr = ["#FB718500, #FB718511, #FB718511, #FB718511, #FB718500", "#34D39900, #34D39911, #34D39911, #34D39911, #34D39900", "#8B5CF600, #8B5CF611, #8B5CF611, #8B5CF611, #8B5CF600"];
const card2ColorArr = ["#74CDFF00, #74CDFF11, #74CDFF11, #74CDFF11, #74CDFF00", "#FB923C00, #FB923C11, #FB923C11, #FB923C11, #FB923C00", "#A3E63500, #A3E63511, #A3E63511, #A3E63511, #A3E63500"];
