"use client";
import { Button } from "@/components/ui/button";
import { CheckCircle } from "lucide-react";
import Image from "next/image";
import { useRouter } from "next/navigation";
import React from "react";
import CardWrapper from "../CardWrapper";
import GradientSpan from "../headers/GradientSpan";
type TData = {
  title: string;
  desc: string;
  package: string[];
  link: string;
  price: string;
};
const JoinProgramCard = ({ data }: { data: TData }) => {
  const router = useRouter();
  const mapProgram = data.package.map((data, i) => {
    return (
      <div key={i} className="flex  mt-5 items-center gap-3">
        <CheckCircle color="#22C55E" size={14} />
        <p className="text-[#FFFFFF] ">{data}</p>
      </div>
    );
  });
  return (
    <CardWrapper className="min-w-[310px] w-1/2 p-8 flex flex-col gap-3  rounded-3xl hide-scrollbar">
      <div>
        <GradientSpan className="text-2xl">{data.title}</GradientSpan>
        <p className="text-xs mt-3">{data.desc}</p>
      </div>
      <div>
        <h2 className="text-sm  text-[#22C55E] mt-5">Package Includes:</h2>
        <div className="mt-5 ">{mapProgram}</div>
      </div>
      <div className="mt-4 flex-[0.7]">
        <Image
          src="https://assets.bonatra.com/Website%20Assets/4.8Rating.webp"
          height={100}
          width={100}
          alt="logo"
        />
        <div className="flex gap-5 mt-2 text-xs">
          <p>
            <span className="font-bold">4.8+</span>Rating
          </p>
          <p>
            <span className="font-bold">200+</span>Reviews
          </p>
        </div>
      </div>
      <div className="flex-[0.3]">
        <h1 className="text-2xl md:text-4xl font-semibold ">
          {data.price}
          <span className="bg-gradient-to-t from-[#FFFFFF69] to-[#FFFF] bg-clip-text text-transparent text-base">
            /month
          </span>
        </h1>
        <Button
          className="bg-[#22C55E] rounded-3xl px-6 mt-5 font-normal"
          onClick={() => router.push(data.link)}
        >
          Buy Now
        </Button>
      </div>
    </CardWrapper>
  );
};

export default JoinProgramCard;
