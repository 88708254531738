'use client'
import { Button } from '@/components/ui/button';
import React, { useState, Fragment } from 'react';
import { useForm, SubmitHandler, SubmitErrorHandler } from 'react-hook-form';
import { useToast } from '@/components/ui/use-toast';
import { countryCode } from '@/lib/countryCodes'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectGroup
} from "@/components/ui/select"
import { Separator } from '@/components/ui/separator';
import axios from 'axios';
import { Input } from '@/components/ui/input';

interface FormData {
  email: string;
  mobile: string;
}

type Timezone = {
  [key: string]: string | undefined;
};

type Iso = {
  "alpha-2": string;
  "alpha-3": string;
  numeric: string;
};

type CountryObjType = {
  name?: string;
  region?: string;
  timezones?: Timezone;
  iso?: Iso
  phone?: string[];
  emoji?: string;
  image?: string;
  phoneLength?: number | number[];
}

const LeadForm: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [selectedCountry, setSelectedCountry] = useState<CountryObjType>({
        "name": "India",
        "region": "Asia",
        "timezones": {
            "Asia/Kolkata": "+05:30"
        },
        "iso": {
            "alpha-2": "IN",
            "alpha-3": "IND",
            "numeric": "356"
        },
        "phone": [
            "+91"
        ],
        "emoji": "🇮🇳",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg",
        "phoneLength": 10
    })
    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm<FormData>();
    const { toast } = useToast();

  const onError: SubmitErrorHandler<FormData> = (errors) => {
    console.error(errors);
  };

  const handleSelectChange = (value: string) => {
    const foundCountry: CountryObjType | undefined = countryCode.find(country => country.name === value);
    if (foundCountry) {
      setSelectedCountry(foundCountry);
    }
  };

const onSubmit = async (values: any) => {
  values["email_id"] = email;
  const urlParams = new URLSearchParams(window.location.search);
  const utmParams = {
    utmSource: urlParams.get('utm_source'),
    utmMedium: urlParams.get('utm_medium'),
    utmCampaign: urlParams.get('utm_campaign'),
    utmTerm: urlParams.get('utm_term'),
    utmContent: urlParams.get('utm_content'),
  };
  values["utmSource"] = utmParams.utmSource;
  values["utmMedium"] = utmParams.utmMedium;
  values["utmCampaign"] = utmParams.utmCampaign;
  values["utmTerm"] = utmParams.utmTerm;
  values["utmContent"] = utmParams.utmTerm;
  values["mobile_no"] = selectedCountry?.phone + "-" + mobile;

  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/api/method/bonatra.templates.includes.consultationform.create_lead`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: 'Ring Page',
          ...values,
        }),
      }
    );

    if (response.ok) {
      console.log("response.ok")
      toast({
        title: "Thank you for your interest.",
        description: "Our expert will contact you soon",
        variant: "successGreen"
      });
      reset();
    } else {
      const errorData = await response.json();
      console.error("CreateLead Error:", errorData);
      toast({
        title: "Error: ",
        description: errorData,
        variant: "destructive"
      });
    }
  } catch (error) {
    // Handle the error if needed
    console.error("CreateLead Error:", error);
    toast({
      title: "CreateLead Error",
      variant: "destructive"
    });
  }
};


const countryOptions = countryCode.map((country,i) => (
    <SelectItem className='font-semibold border-b-2 border-black py-4 bg-white hover:bg-violet-500 hover:text-white' key={`item-${i}`} value={country?.name!}> 
      {`${country?.emoji}\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0${country?.name}\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0(${country?.phone})`}
    </SelectItem>
))

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} className="flex flex-col mx-auto gap-4 mt-4 w-full md:w-[50%]">
      <div className="flex flex-col items-center gap-4 w-full">
        <Input
          {...register('email', {
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: 'Invalid email address',
            },
          })}
          placeholder="Email Address"
          className="border p-2 w-full md:w-[50%] rounded-lg text-black"
          onChangeCapture={e => setEmail(e.currentTarget.value)}
          id="email"
          name="email" 
          autoComplete="email" 
        />
        {errors.email && <span className='text-red-600 text-semibold'>{errors.email.message}</span>}

        <div className='flex flex-row justify-center gap-3 items-baseline w-full'>
          {/* <div className='w-[15%] h-[2.6125rem] rounded-lg p-2 mt-[15px] border-[2px] border-solid border-[#D9D9D9]' onClick={toggleCountryCodeModal}>{selectedCountry?.emoji}{selectedCountry?.phone}</div> */}
          <Select onValueChange={(value) => handleSelectChange(value)} defaultValue={selectedCountry.name}>
            <SelectTrigger className="w-[200px] bg-white text-black">
              <SelectValue>
                <span className="mr-6">{selectedCountry?.emoji}</span>
                {/* <span className="mr-6">{selectedCountry?.name}</span> */}
                <span>({selectedCountry?.phone})</span>
              </SelectValue>
            </SelectTrigger>
            <SelectContent className='bg-white text-black'>
              <SelectGroup>{countryOptions}</SelectGroup>
            </SelectContent>
          </Select>
          <Input
            {...register('mobile', {
              pattern: {
                value: /^\d{10}$/,
                message: 'Invalid mobile number',
              },
            })}
            placeholder="Mobile Number"
            className="border h-[2.6125rem] p-2 w-auto md:w-[50%] rounded-lg text-black"
            onChangeCapture={e => setMobile(e.currentTarget.value)}
            id="mobile"
            name="mobile" 
            autoComplete="tel"
          />
          {errors.mobile && <span className='text-red-600 text-semibold'>{errors.mobile.message}</span>}
        </div>
      </div>

      <Button
        type="submit" 
        className="bg-[#22C55E] mt-4 text-black w-32 mx-auto font-normal"
      >
        Submit
      </Button>
    </form>
  );
};

export default LeadForm;
