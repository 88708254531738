import { Card } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import React from "react";

const CardWrapper = ({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <Card
      className={cn("bg-black rounded-3xl p-4 border-none", className)}
      {...props}
    >
      {children}
    </Card>
  );
};

export default CardWrapper;
