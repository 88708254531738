"use client";
import React from "react";
import Section from "@/components/new-components/wrapper/Section";
import Link from "next/link";

export default function HeroSection() {
  return (
    <Section className="md:text-center flex flex-col items-center pt-10 pb-10">
      <h2 className="font-semibold text-4xl md:text-5xl md:leading-[4.375rem] mb-3">
        Empower Your <span className="text-[#22C55E]">Longevity</span> with
        AI-Driven Wellness
      </h2>
      <p className="font-medium text-lg mb-5 ">
        Unlock holistic health revelations with our cutting-edge app.
      </p>
      <Link
        href={"#joinProgram"}
        className="bg-[#22C55E] rounded-3xl py-2 px-8 text-black"
      >
        Join Now
      </Link>
    </Section>
  );
}
