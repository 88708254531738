"use client";
import React from "react";
import Section from "../wrapper/Section";
import Image from "next/image";
import blogImg1 from "@/assets/Images/Inspirational Stories.webp";
import blogImg2 from "@/assets/Images/Glycemic Variability Demystified.webp";
import blogImg3 from "@/assets/Images/Intermittent Fasting.webp";
import blogImg4 from "@/assets/Images/Fats Myths & Facts.webp";
import blogImg5 from "@/assets/Images/Mediterranean Diet.webp";
import blogImg6 from "@/assets/Images/Unhealthy Side of Cholesterol.webp";
import Link from "next/link";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { ChevronRight } from "lucide-react";
import { useRouter } from "next/navigation";
import { cn } from "@/lib/utils";

import H1 from "@/app/components/headers/H1";
const Blogs = ({
  className,
  title,
  titleGreen,
}: {
  className?: string;
  title?: string;
  titleGreen?: string;
}) => {
  const router = useRouter();
  const titleText = title ? title : "Blogs";
  const spanText = titleGreen ? titleGreen : "";

  return (
    <Section className="relative">
      <H1 className="mb-5">
        {titleText} <span className="text-[#22C55E]">{spanText}</span>
      </H1>
      <div className="flex overflow-x-scroll hide-scrollbar">
        <div className="flex gap-4 px-2">
          {blogData.map((item, i) => {
            return (
              <Card
                key={i}
                className={cn(
                  "w-80 mb-6 justify-center rounded-[5px]   border-2 border-[#ebeaea]",
                  className
                )}
                style={{ boxShadow: "rgba(0, 0, 0, 0.15) 2.95px 2.95px 1.6px" }}
              >
                <CardHeader className="space-y-0 p-2    ">
                  <Image src={item.img} alt="img" />
                </CardHeader>
                <CardContent className="p-2 px-4">
                  <h2 className="font-bold">{item.title}</h2>
                  <p className="mt-4 ">{item.desc}</p>
                  <p
                    className={`pt-10 ${
                      titleGreen ? "text-[#22C55E]" : "text-[#22C55E]"
                    }`}
                  >
                    <Link href={item.link}>Read more...</Link>
                  </p>
                </CardContent>
              </Card>
            );
          })}
          <div className="flex items-center justify-center">
            <ChevronRight
              size={40}
              color={"gray"}
              className="hover:cursor-pointer hover:text-green-600"
              onClick={() => router.push("https://blog.thehealthspan.ai/")}
            />
          </div>
        </div>
      </div>
      <div className=" absolute  right-0 top-2/4 -rotate-90 lg:hidden">
        <div className="animate-bounce">
          <ChevronRight className="rotate-90 " size={30} />
        </div>
      </div>
    </Section>
  );
};

export default Blogs;
const blogData = [
  {
    img: blogImg1,
    title: "Inspirational Stories of Famous Personalities with Diabetes",
    desc: "Diabetes is a global health condition that affects millions, including celebrities and public figures who have turned their personal battles into public narratives of inspiration and awareness.",
    link: "https://blog.thehealthspan.ai/2023/12/31/inspirational-stories-of-famous-personalities-with-diabetes/",
  },
  {
    img: blogImg2,
    title: "Glycemic Variability Demystified",
    desc: "Glycemic variability (GV) refers to the degree of fluctuation in blood glucose levels over time. It is a measure of how often and how much blood glucose levels change",
    link: "https://blog.thehealthspan.ai/2023/03/05/glycemic-variability-demystified/",
  },
  {
    img: blogImg3,
    title: "Intermittent Fasting – The what,how, and why",
    desc: "While research on the subject is still ongoing, studies have suggested that intermittent fasting  may have several potential benefits",
    link: "https://blog.thehealthspan.ai/2023/01/13/intermittent-fasting-the-what-how-and-why/",
  },
  {
    img: blogImg4,
    title: "Fats – Myths & Facts",
    desc: "A low-fat diet was long thought to be the key to controlling health problems and achieving weight loss by many doctors and nutritionists.",
    link: "https://blog.thehealthspan.ai/2022/10/26/fats-myths-facts/",
  },
  {
    img: blogImg5,
    title: "Exploring the Mediterranean Diet for Healthy Aging",
    desc: "The Mediterranean diet, renowned for its rich culinary heritage, has emerged as a blueprint for healthy aging.",
    link: "https://blog.thehealthspan.ai/2023/11/30/exploring-the-mediterranean-diet-for-healthy-aging/",
  },
  {
    img: blogImg6,
    title: "The Good, The Bad, and The Unhealthy Side of Cholesterol",
    desc: "Despite the negative image, cholesterol is a waxy substance that accumulates in the arteries and is not wholly toxic.",
    link: "https://blog.thehealthspan.ai/2022/10/22/the-good-the-bad-and-the-unhealthy-side-of-cholesterol/",
  },
];
