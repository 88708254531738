import(/* webpackMode: "eager" */ "/vercel/path0/app/components/aiBasedTechnology/AiBasedTechnoly.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/components/foundedBy/FounderCard.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/components/joinOurPrograms/JoinProgramCard.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/components/newRevo/NewRevo.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/components/personalAiAdvisor/PersonalAiAdvisor.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/app/testss/app-demo-screenshot-section/AppDemoScreenshotSection.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/testss/hero-section/HeroSection.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/testss/leads-form/LeadForm.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/testss/unlock-your-true-age-section/UnlockYourTrueAgeSection.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/testss/your-everyday-health-score/YourEverydayHealthScore.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/assets/Images/Irfana Shaik.png");
import(/* webpackMode: "eager" */ "/vercel/path0/assets/Images/Raj Gupta.png");
import(/* webpackMode: "eager" */ "/vercel/path0/assets/Images/Umesh Mehta.png");
import(/* webpackMode: "eager" */ "/vercel/path0/components/new-components/blogs/Blogs.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js")