import { cn } from "@/lib/utils";
import React from "react";

const P = ({
  children,
  className,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>) => {
  return (
    <p
      {...props}
      className={cn(
        "text-[#797979] font-medium text-lg lg:text-2xl md:text-xl",
        className
      )}
    >
      {children}
    </p>
  );
};

export default P;
