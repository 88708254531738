"use client";
import React from "react";
import Image from "next/image";
import Section from "@/components/new-components/wrapper/Section";
import CardWrapper from "@/app/components/CardWrapper";
import GradientSpan from "@/app/components/headers/GradientSpan";
import { useRouter } from "next/navigation";

const AppDemoScreenshotSection = () => {
  const router = useRouter();
  return (
    <>
      <Section>
        <CardWrapper className="p-0 relative h-[400px] w-full md:h-[700px] lg:h-[900px] hidden md:block">
          <div className="relative z-10 h-[800px] lg:w-[40%] md:w-[50%] p-6 px-8 ">
            <p className="bg-gradient-to-t from-[#FFFFFF] to-[#FFFF] bg-clip-text text-transparent font-semibold lg:text-3xl md:text-xl">
              Streamline your health journey
            </p>
            <GradientSpan className="lg:text-3xl font-semibold  md:text-xl">
              with our unified Dashboard
            </GradientSpan>
            <p className="lg:text-3xl md:text-xl font-medium mt-8 text-[#797979]">
              seamlessly integrating data from{" "}
              <span className="text-white">
                Bonatra&apos;s Smart Ring, Google Fit, CGM and Apple Health.
              </span>{" "}
              Gain insights effortlessly, guiding you towards your wellness
              goals.
            </p>
            <div className="flex gap-5 mt-6">
              <Image
                src="https://assets.bonatra.com/Website%20Assets/Appstore.webp"
                height={100}
                width={100}
                alt="logo"
                className=" hover:cursor-pointer"
                onClick={() =>
                  router.push(
                    "https://apps.apple.com/in/app/bonatra/id1600079061"
                  )
                }
              />
              <Image
                src="https://assets.bonatra.com/Website%20Assets/Playstore.webp"
                height={100}
                width={100}
                alt="logo"
                className=" hover:cursor-pointer"
                onClick={() =>
                  router.push(
                    "https://play.google.com/store/apps/details?id=com.yoihealth"
                  )
                }
              />
            </div>
          </div>
          <Image
            src="https://assets.bonatra.com/Website%20Assets/Appdash.webp"
            alt=""
            fill
            style={{ objectFit: "fill" }}
          />
        </CardWrapper>
      </Section>
      <div className="md:hidden">
        <Image
          src="https://assets.bonatra.com/Website%20Assets/Appdash.webp"
          alt=""
          height={900}
          width={900}
        />
        <Section>
          <p className="bg-gradient-to-t from-[#FFFFFF] to-[#FFFF] bg-clip-text text-transparent font-semibold text-xl mt-5">
            Streamline your health
          </p>
          <GradientSpan className="text-xl font-semibold  ">
            Journey with our unified Dashboard
          </GradientSpan>
          <p className="text-xl font-medium mt-5 text-[#797979]">
            seamlessly integrating data from{" "}
            <span className="text-white">
              Bonatra&apos;s Smart Ring, Google Fit, and Apple Health.
            </span>{" "}
            Gain insights effortlessly, guiding you towards your wellness goals.
          </p>
          <div className="flex gap-5 mt-6">
            <Image
              src="https://assets.bonatra.com/Website%20Assets/Appstore.webp"
              height={150}
              width={150}
              alt="logo"
              onClick={() =>
                router.push(
                  "https://apps.apple.com/in/app/bonatra/id1600079061"
                )
              }
            />
            <Image
              src="https://assets.bonatra.com/Website%20Assets/Playstore.webp"
              height={150}
              width={150}
              alt="logo"
              onClick={() =>
                router.push(
                  "https://play.google.com/store/apps/details?id=com.yoihealth"
                )
              }
            />
          </div>
        </Section>
      </div>
    </>
  );
};

export default AppDemoScreenshotSection;
